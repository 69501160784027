import {httpDelete, httpGet, httpPost, httpPut} from '@/plugins/request'


/**
 * 获取收入列表
 *
 * @param query
 * @returns {Promise<*>}
 */
export function apiGetProjectPay(query = [])
{
    return httpGet('/manage/project/pay', query)
}

/**
 * 新增收入
 *
 * @param data
 * @returns {Promise<*>}
 */
export function apiStoreProjectPay(data)
{
    return httpPost('/manage/project/pay', data)
}

/**
 * 修改收入
 * @param id
 * @param data
 * @returns {Promise<*>}
 */
export function apiUpdateProjectPay(id, data)
{
    return httpPut(`/manage/project/pay/${id}`, data)
}

/**
 * 删除收入
 *
 * @param id
 * @param query
 * @returns {Promise<*>}
 */
export function apiDestroyProjectPay(id, query)
{
    return httpDelete(`/manage/project/pay/${id}`, query)
}
