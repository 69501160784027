<template>
  <div>
    <!----- 搜索框 start------->
    <el-form :inline="true" class="search">
      <el-form-item label="项目/合同">
        <el-input clearable v-model="searchData.name" placeholder="关键字搜索"/>
      </el-form-item>
      <el-form-item label="备注">
        <el-input clearable v-model="searchData.remark" placeholder="名称"/>
      </el-form-item>
      <el-form-item label="类型">
        <el-select clearable style="width: 120px" v-model="searchData.pay_type">
          <el-option label="未收" :value="0"></el-option>
          <el-option label="公账" :value="1"></el-option>
          <el-option label="私账" :value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="收款">
        <el-select clearable style="width: 120px" v-model="searchData.padding">
          <el-option label="待收" value="1"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-button @click="getPay()">查询</el-button>
      </el-form-item>

      <!--
        <el-form-item style="float: right">
            <el-button type="primary" @click.stop="clickCreate()">添加支出</el-button>
        </el-form-item>
        -->
    </el-form>
    <!----- 搜索框 end------->

    <!----- 表格 start------->
    <el-table :data="tableData" :border="true" empty-text="暂无数据" style="width: 100%">
      <el-table-column prop="id" label="ID"/>
      <el-table-column prop="contract_name" label="合同"/>
      <el-table-column prop="contract_amount" label="合同金额"/>
      <el-table-column prop="price" label="应收金额"/>
      <el-table-column prop="date" label="应收日期"/>
      <el-table-column prop="pay_price" label="实收金额"/>
      <el-table-column prop="pay_date" label="实收日期"/>
      <el-table-column prop="pay_type_desc" label="收款方式"/>
      <el-table-column prop="remark" label="备注"/>
      <el-table-column label="操作">
        <template #default="scope">
          <el-button type="primary" size="small" @click="clickUpdate(scope.row)">修改</el-button>
          <el-button type="danger" size="small" @click="clickDestroy(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!----- 表格 end------->


    <!----- 分页 start------->
    <div class="cherry-page" v-show="pageData.total">
      <el-pagination
          small
          background
          layout="prev, pager, next, total"
          :total="pageData.total"
          v-model:current-page="searchData.page"
          :page-size="searchData.per_page"
          @update:current-page="getPay()"
          class="mt-4"
      />
    </div>
    <!----- 分页 end------->

    <!-------- 弹出框表单 start ---------->
    <el-dialog v-model="formData.show" title="项目收款" fullscreen append-to-body>
      <el-form
          ref="contract.pay"
          :rules="formData.rules"
          label-width="100px"
          :model="formData.data"
      >
        <el-form-item label="应付金额" prop="price">
          <el-input v-model="formData.data.price" placeholder="应付金额"/>
        </el-form-item>
        <el-form-item label="应付时间" prop="date">
          <el-date-picker v-model="formData.data.date" value-format="YYYY-MM-DD" type="date"
                          placeholder="应付时间"/>
        </el-form-item>
        <el-form-item label="实付金额" prop="pay_price">
          <el-input v-model="formData.data.pay_price" placeholder="实付金额"/>
        </el-form-item>
        <el-form-item label="实付时间" prop="pay_date">
          <el-date-picker v-model="formData.data.pay_date" value-format="YYYY-MM-DD" type="date"
                          placeholder="实付时间"/>
        </el-form-item>
        <el-form-item label="付款方式" prop="pay_type">
          <el-select v-model="formData.data.pay_type">
            <el-option label="未付" :value="0"></el-option>
            <el-option label="公账" :value="1"></el-option>
            <el-option label="私账" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input v-model="formData.data.remark" placeholder="备注"/>
        </el-form-item>
      </el-form>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="closeDialog">取消</el-button>
          <el-button type="primary" @click="onSubmit">提交</el-button>
        </span>
      </template>
    </el-dialog>
    <!-------- 弹出框表单 end ---------->
  </div>
</template>

<script>
import {
  apiGetProjectPay,
  apiStoreProjectPay,
  apiUpdateProjectPay,
  apiDestroyProjectPay
} from '@/api/projectPayApi'
import {getCompanyId} from '@/plugins/company'

let formData = {
  company_id: getCompanyId(),
  id: '',
  project_id: '',
  project_contract_id: '',
  date: null,
  pay_date: null,
  price: '',
  pay_price: 0,
  pay_type: 0,
  remark: '',
  serial_no: '',
}

export default {
  name: 'project.pay',
  created() {
    this.getPay()
  },
  data() {
    return {
      // 创建表单
      formData: {
        lock: false,
        data: formData,
        rules: {
          price: [{
            required: true,
            message: '请输入家呢',
            trigger: 'blur',
          }],
          date: [{
            required: true,
            message: '请选择日期',
            trigger: 'blur',
          }],
        },
        show: false,
      },
      tableData: [],
      searchData: {
        company_id: getCompanyId(),
        name: '',
        padding: null,
        remark: '',
        pay_type: '',
        page: 1,
        per_page: 15,
      },
      pageData: {
        total: 0,
      }
    }
  },
  methods: {
    getPay() {
      apiGetProjectPay(this.searchData).then((res) => {
        this.tableData = res.data
        this.pageData = res.meta
      })
    },
    onSubmit() {
      if (this.formData.lock) {
        return;
      }
      this.formLock()
      this.$refs['contract.pay'].validate(valid => {
        if (valid) {
          // 更新逻辑
          if (this.formData.data.id > 0) {
            apiUpdateProjectPay(this.formData.data.id, this.formData.data).then(() => {
              this.$message.success('修改成功')
              this.getPay()
              this.closeDialog()
              this.formUnlock()
            }).catch(() => {
              this.formUnlock()
            })
          } else {
            apiStoreProjectPay(this.formData.data).then(() => {
              this.$message.success('创建成功')
              this.getPay()
              this.closeDialog()
              this.formUnlock()
            }).catch(() => {
              this.formUnlock()
            })
          }
        }
      })
    },
    // 点击更新
    clickUpdate(row) {
      this.formData.data = row
      this.showDialog()
    },
    clickDestroy(row) {
      this.$confirm('确定删除吗').then(() => {
        apiDestroyProjectPay(row.id).then(() => {
          this.getPay()
          this.$message.success('删除成功')
        })
      })
    },
    // 点击创建
    clickCreate() {
      this.initDialog()
      this.showDialog()
    },
    formLock() {
      this.formData.lock = true
    },
    formUnlock() {
      this.formData.lock = false
    },
    // 初始化弹出
    initDialog() {
      this.formData.data = formData
      this.closeDialog()
    },
    // 显示弹窗
    showDialog() {
      this.formData.show = true
    },
    // 关闭弹窗
    closeDialog() {
      this.formData.show = false
    }
  }
}
</script>
